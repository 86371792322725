/* 
    Here import all the 
    components and make the route 
    of that components.
*/

import Vue from 'vue'
import VueRouter from 'vue-router'
import Welcome from './components/Welcome.vue'
import ViewSite from './components/ViewSite'
import SiteDetails from './components/ViewSitesLogs/Sites_Details'
import Login from './components/Auth/Login'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

 
Vue.use(NProgress)
Vue.use(VueRouter)

const routes = [
    {
        path : '/',
        component : Login,
        name: 'Login'
    },
    {
        path: '/dashboard',
        component: Welcome,
        name: 'Welcome',
        beforeEnter:(to , from ,next)=>{
            if(localStorage.getItem('token')){
                next();
            }else{
                next('/login');
            }
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/site/:id/:key',
        component: SiteDetails,
        name: 'Sites',
        props:true,
    },
    {
        path:'/view-site/:site',
        component:ViewSite,
        name : 'View-Site',
        props :true
    }
    
]

/* 
    Here for every route 
    it gets the token from LocalStorage
    and then assign that token to every route
*/
 
const router = new VueRouter({
    mode: 'history',
    routes,
    linkActiveClass: 'active',
    linkExactActiveClass : 'exact-active',
})

router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start().configure({ easing: 'ease', speed: 500 });
    }
    next()
});

router.afterEach((to, from) => {
    NProgress.done().configure({ easing: 'ease', speed: 500 });
});
router.beforeEach((to, from, next) => {
    const token =localStorage.getItem('token') || null 
    window.axios.defaults.headers['Authorization'] = 'Bearer ' + token;
    next();   
});


export default router;