<template>
<div>
    <div v-if="loading1" id="square">
        <square></square>
    </div>
    <nav class="navbar navbar-expand-lg navbar-light bg-white">

        <router-link to="/dashboard" class="navbar-brand"> <img src="https://www.kartmax.in/wp-content/uploads/elementor/thumbs/kartmax-logo-pc63h0kkryi0puqjq26v2a2g0wrzlymed8ln17mups.png" style="height: 25px; width: 130px;"></router-link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse " id="navbarNav">
            <ul class="navbar-nav ml-auto">
                <p class="font-weight-bold">{{user.name}}</p> &nbsp; &nbsp;
                <li class="nav-item active">
                    <button class="btn btn-sm btn-danger" @click="logout">Logout</button>
                </li>
            </ul>

            <!-- <p v-if="user.is_admin" class="text-danger font-weight-bold">Admin</p> <br> -->
        </div>

    </nav>
</div>
</template>

<script>
export default {
    data() {
        return {
            user: '',
            loading1: false
        }
    },

    /* hitting api for logout and removing
       passport token from localStorage
    */

    methods: {
        async logout() {
            try {
                this.loading1 = true;
                let response = await axios.post('/api/logout');
                if (response) {
                    localStorage.removeItem('token');
                    this.$router.push('/').then(res => {
                        /* this.$swal({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                icon: 'success',
                                title: 'Logged Out successfully'
                            }) */
                    }).catch(e => {
                        console.log(e)
                    })
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.loading1 = false;
            }
        },
    },

    /*
        calling api of user when user successfully logged in.
    */
    async created() {
        let response = await axios.get('/api/logged-in')
            .then(response => {
                this.user = response.data.data;
            })
    }

};
</script>

<style>
</style>
