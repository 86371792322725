<template>
<div>
    <p>
        Please copy the below mentioned keys for different environment
        and paste in your app's environment file
    </p>
    <p>
        <b>BUGFILE_END_POINT=</b><code id="end-point">https://logs.kartmax.in/api/logs</code>
        <b>BUGFILE_KEY_LIVE=</b><code id="live">{{ details.data.live }}</code>
        <b>BUGFILE_KEY_STAGING=</b><code id="staging">{{ details.data.staging }}</code>
        <b>BUGFILE_KEY_DEV=</b><code id="dev">{{ details.data.dev }}</code>
    </p>
</div>
</template>

<script>
export default {
    props: ['details']
}
</script>

<style>

</style>
