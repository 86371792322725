<template>
<div>
    <div v-if="loadingKey" id="square">
        <square></square>
    </div>
    <!-- Modal -->
    <div class="modal fade bd-example-modal-lg" :id="'keyModal'" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">

                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">KEY LISTS</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class='modal-body '>
                    <div class="alert alert-danger" role="alert" v-if="key_message">
                        {{ key_message }}
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="container">
                        <div class="row" v-for="api in api_key" :key="api.id">
                            <div class="col-sm-12">
                                <b class="text-uppercase">BUGFILE_KEY_{{api.name.split("-").pop()}}= </b>
                                <code class="text-center text-justify" id="end-point">{{api.key}}</code>
                            </div>
                        </div> <br>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="close" data-dismiss="modal">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import {
    mapActions,
    mapState
} from "vuex";
export default {
    props: ["keyFetch", "loadingKey"],
    data() {
        return {

        };
    },

    /* 
        Watcher here is used to watch the 
        the id for the respective site 
        and fetch the Key.
    */
    watch: {
        keyFetch: function (val) {
            this.fetchApiKey(this.keyFetch)
        }
    },

    /* 
        calling the required state from
        dashboard Vuex and config
    */

    computed: {
        ...mapState("dashboard", ["api_key", "key_message"]),
    },

    /* 
        dispatching the actions to the dashboard Vuex
    */
    methods: {
        ...mapActions("dashboard", ["fetchApiKey", "resetApiKey"]),
        close() {
            this.resetApiKey();
        }
    },
};
</script>

<style>
#square {
    z-index: 1;
    position: absolute;
    left: 50%;

}
</style>
