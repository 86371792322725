import axios from "axios"

export default {

    /* 
        Defining the States
        which will now be access to the 
        components.
    */
    state: {
        loading: false,
        dashboard: [],
        sites: [],
        stackTrace: [],
        resolve: '',
        site_info: [],
        addSite: {
            site: '',
            label: [],
            noOfOccurance:[]
        },
        data: {
            alert_value: ''
        },
        add_site: '',
        deleteSingle: '',
        errors1: '',
        api_key: [],
        editData: [],
        updateData: '',
        user: '',
        key_message: '',
        editSite: {},
        deleteMessage: ''
    },

    /* 
        Defining all the mutations
        which get data from api's
        and assiging data to the 
        States.
    */
    mutations: {
        SET_LOADING(state, loading) {
            state.loading = loading;
        },

        SET_DASHBOARD(state, data) {
            state.dashboard = data;
        },

        SET_SITES(state, data) {
            state.sites = data
        },

        SET_STACK_TRACE(state, { data }) {
            state.stackTrace = data
        },

        SET_RESOLVE(state, data) {
            state.resolve = data;
        },

        SET_SITE_INFO(state, { data }) {
            state.site_info = data;
        },

        ADD_SITE(state, data) {
            state.add_site = data;
        },

        SET_DELETE_SINGLE_SITE(state, data) {
            state.deleteSingle = data;
        },

        SET_ERROR(state, data) {
            state.errors1 = data
        },

        SET_PROJECT_API(state, { data }) {
            state.api_key = data;
        },

        SET_EDIT(state, { data }) {
            state.editSite = data;
        },

        SET_UPDATE(state, data) {
            state.updateData = data;
        },

        SET_USER(state, data) {
            state.user = data;
        },

        SET_KEY_MESSAGE(state, data) {
            state.key_message = data;
        },

        REMOVE_ADD_SITE(state) {
            state.addSite.site = '',
                state.addSite.label = []
        },

        REMOVE_EDIT_SITE(state) {
            state.editSite.site = '',
            state.editSite.alerts[0].alert_value = ''
            state.editSite.alerts[1].alert_value = ''
            state.editSite.alerts[2].alert_value = ''
            state.editSite.alerts[3].alert_value = ''
            state.editSite.alerts[4].alert_value = ''
            state.editSite.alerts[5].alert_value = ''
            state.editSite.alerts[6].alert_value = ''
            state.editSite.alerts[7].alert_value = ''
        },

        REMOVE_EDIT_SITE_DATA(state) {
            state.editSite = []
        },

        SET_DELETE_MODAL_MESSAGE(state, data) {
            state.deleteMessage = data
        },

        REMOVE_ADD_SITE_MODAL(state) {
            state.add_site = ''
        }
    },

    /* 
        Defined all the actions 
        which is used for calling the
        api's.
    */

    actions: {
        async fetchDashboard({ commit, state }, query) {

            try {
                commit("SET_LOADING", true);
                let response = await axios.get('/api/dashboard' + query);
                commit("SET_DASHBOARD", response.data.data);
                return true;
            } catch (error) {
                console.log(error);
            } finally {
                commit("SET_LOADING", false);
            }
        },

        async fetchSite({ commit, state }, payload) {
            commit("SET_SITES", []);
            commit("SET_ERROR", '');
            try {
                commit("SET_LOADING", true);
                let response = await axios.get('/api/site/' + payload);
                commit("SET_SITES", response.data.data);
                return true;
            } catch (error) {
                commit("SET_ERROR", error.response.data.message);
            } finally {
                commit("SET_LOADING", false);
            }
        },

        async searchSite({ commit, state }, payload) {
            commit("SET_SITES", []);
            commit("SET_ERROR", '');
            try {
                commit("SET_LOADING", true);
                let response = await axios.get('/api/site/' + payload.id,{
                    params:payload.searchquery
                });
                commit("SET_SITES", response.data.data);
                return true;
            } catch (error) {
                console.log(error)
                // commit("SET_ERROR", error.response.data.message);
            } finally {
                commit("SET_LOADING", false);
            }
        },

        async fetchstackTrace({ commit, state }, payload) {
            commit("SET_STACK_TRACE", []);
            try {
                commit("SET_LOADING", true);
                let response = await axios.get('/api/stacktrace/' + payload);
                commit("SET_STACK_TRACE", response.data.data);
                return true;
            } catch (error) {
                console.log(error);
            } finally {
                commit("SET_LOADING", false);
            }
        },

        removeStack({ commit, state }) {
            commit("SET_STACK_TRACE", []);
        },

        async fetchResolve({ commit, state }, payload) {
            try {
                commit("SET_LOADING", true);
                let response = await axios.get('/api/status-update/' + payload);
                commit("SET_RESOLVE", response.data.message);
                return true;
            } catch (error) {
                console.log(error);
            } finally {
                commit("SET_LOADING", false);
            }
        },

        async fetchSiteInfo({ commit, state }, payload) {
            commit("SET_SITE_INFO", []);
            try {
                commit("SET_LOADING", true);
                let response = await axios.get('/api/site-info/' + payload);
                commit("SET_SITE_INFO", response.data);
                return true;
            } catch (error) {
                console.log(error);
            } finally {
                commit("SET_LOADING", false);
            }
        },

        removeSiteInfo({ commit }) {
            commit("SET_SITE_INFO", [])
        },

        async addSiteDetails({ commit, state }, payload) {
            commit("ADD_SITE", []);
            try {
                console.log(payload)
                commit("SET_LOADING", true);
                let response = await axios.post('/api/add-site', payload);
                commit("ADD_SITE", response.data);
                return true;
            } catch (error) {
                console.log(error);
            } finally {
                commit("SET_LOADING", false);
            }
        },

        async deleteDashboard({ commit, state }, payload) {

            try {
                commit("SET_LOADING", false);
                let response = await axios.get('/api/delete-complete-site/' + payload.id);
                if (response.data.success) {
                    state.dashboard.data.splice(payload.indexID, 1)
                }
                commit("SET_DELETE_SINGLE_SITE", response.data.message);
                return true;
            } catch (error) {
                commit("SET_DELETE_MODAL_MESSAGE", error.response.data.message);
            } finally {
                commit("SET_LOADING", false);
            }
        },

        async deleteSingleSite({ commit, state }, payload) {
            try {
                commit("SET_LOADING", false);
                let response = await axios.get('/api/delete-single-site/' + payload.id);
                if (response.data.success) {
                    state.site_info[0].sites.splice(payload.indexID, 1)
                }
                commit("SET_DELETE_SINGLE_SITE", response.data.message);
                return true;
            } catch (error) {
                commit("SET_DELETE_MODAL_MESSAGE", error.response.data.message);
            } finally {
                commit("SET_LOADING", false);
            }
        },

        async fetchApiKey({ commit, state }, payload) {
            commit("SET_PROJECT_API", []);
            try {
                commit("SET_LOADING", true);
                let response = await axios.get('/api/get-project_api-key/' + payload);
                commit("SET_PROJECT_API", response.data);
                return true;
            } catch (error) {
                commit("SET_KEY_MESSAGE", error.response.data.message);
            } finally {
                commit("SET_LOADING", false);
            }
        },

        resetApiKey({ commit, state }) {
            commit("SET_PROJECT_API", []);
        },



        async EditSite({ commit, state }, payload) {

            try {
                commit("SET_LOADING", true);
                let response = await axios.get('/api/edit-site/' + payload);
                commit("SET_EDIT", response.data);
                return true;
            } catch (error) {
                console.log(error);
            } finally {
                commit("SET_LOADING", false);
            }
        },

        async updateSiteDetails({ commit, state }, params) {
            try {
                console.log("params", params)
                commit("SET_LOADING", true);
                let response = await axios.post('/api/update-site/' + params.id, params.editSite);
                commit("SET_UPDATE", response.data);
                return true;
            } catch (error) {
                console.log(error);
            } finally {
                commit("SET_LOADING", false);
            }
        },

        removeUpdate({ commit }) {
            commit("SET_EDIT", []);
        },

        async fetchUser({ commit, state }) {
            try {
                commit("SET_LOADING", true);
                let response = await axios.get('/api/logged-in');
                commit("SET_USER", response.data);
                return true;
            } catch (error) {
                console.log(error);
            } finally {
                commit("SET_LOADING", false);
            }
        }
    }
}