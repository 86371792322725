<template>
<div>
    <div v-if="loading" id="square">
        <square></square>
    </div>
    <!-- <div class="alert alert-success" role="alert" v-if="deleteSingle">
        {{ deleteSingle }}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div> -->

    <div class="container-fluid">
        <div class="card">
            <div class="card-header font-weight-bold text-uppercase">
                Site Details
            </div>

            <div class="card-body">
                <div class="row" v-for="dash in site_info" :key="dash.id">
                    <div class="col-sm-4" v-show="hideData != site.id" v-for="(site,index) in dash.sites" :key="site.id">

                        <div class="card" style="width: 20rem">
                            <div class="card-body">

                                <a href="#"><i class="far fa-times-circle" style="padding-left: 250px; color: red" @click="deletes(site.id,index)"></i></a>

                                
                                <h5 class="card-title text-uppercase">{{ site.site_key }}</h5>
                                <h6 class="card-subtitle mb-2 text-muted">
                                    {{ dash.name }}
                                </h6>
                                <br />
                                <router-link :to="{ name: 'Sites', params: { id: site.id ,key:site.site_key} }" class="btn btn-sm btn-danger">View Logs &nbsp; <i class="fas fa-eye"></i></router-link> &nbsp;&nbsp;
                                <span>
                                    <toggle-button @change="onChangeEventHandler(site.id)"  
                                                        v-model="site.status" 
                                                        :labels="{checked: 'On', unchecked: 'Off'}"
                                                        style="margin-top: 8px;"
                                                            />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import {
    mapState,
    mapActions
} from "vuex";
export default {
    props: ["site"],
    data() {
        return {
            hideData: '',
            message:''
        };
    },

    // calling the dashboard state from Vuex
    computed: {
        ...mapState("dashboard", ["site_info", "loading", "deleteMessage"]),
    },

    // dispatching the actions to the dashboard Vuex
    methods: {
        ...mapActions("dashboard", ["fetchSiteInfo", "deleteSingleSite", "removeSiteInfo"]),

        deletes(id, indexID) {

            this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.hideData = id
                    this.deleteSingleSite({
                            id,
                            indexID
                        })
                        .then(res => {
                            if (this.deleteMessage) {
                                this.hideData = '',
                                    this.$swal({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: this.deleteMessage,
                                    })
                            }
                        })

                }
            }).catch(error => {

            })

        },

        onChangeEventHandler(site){
            axios.get('/api/enable-disable-single-site/'+site)
            .then((response)=>{
                this.message = response.data.message
                this.$swal(
                                'Updated!',
                                this.message,
                                'success' 
                            )
            })
        }
    },

    // removing the data from add site form after key generation
    mounted() {
        this.$store.commit("dashboard/REMOVE_ADD_SITE_MODAL");
    },

    // calling the lifecycle every time component load
    created() {
        this.fetchSiteInfo(this.site);
    },
};
</script>

<style scoped>
#square {
    z-index: 1;
    position: absolute;
    left: 50%;
}
.pl-5, .px-5 {
    padding-left: 6rem!important;
}
</style>
