require('./bootstrap');
// require('alpinejs');

import Vue from 'vue';
import router from './router'
import store from './store/index'
import Navigation from './components/Header.vue'
import Paginate from 'vuejs-paginate'
import VueSpinners from 'vue-spinners'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VeeValidate from 'vee-validate';
import ToggleButton from 'vue-js-toggle-button'
import vueDebounce from 'vue-debounce'

Vue.use(vueDebounce,{
    lock: false,
    listenTo: 'keyup',
    defaultTime: '300ms',
    fireOnEmpty: false,
    trim: false
  });
Vue.use(ToggleButton);
Vue.use(VeeValidate);
Vue.use(VueSweetalert2);
Vue.use(VueSpinners);
Vue.component('paginate', Paginate)
Vue.component('pagination', require('laravel-vue-pagination'));
window.Vue = require('vue');



const app = new Vue({
    el: '#app',
    router,
    store,
    components: {
        Navigation,
    }
});

export default app;