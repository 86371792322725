<template>
<div>

    <div class="modal" tabindex="-1" role="dialog" id="exampleModal">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add Site</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div v-if="loadingNew" id="square">
                        <square></square>
                    </div>
                    <!-- <div class="alert alert-success" role="alert" v-if="add_site">
                        {{ add_site.message }}
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div> -->
                    <form v-show="!add_site">
                        <div class="form-group" style="padding-bottom: 8px">
                            <label for="exampleInputEmail1">Site Name</label>
                            <input type="text" class="form-control" name="Site" id="site" v-validate="'required'" placeholder="Enter name" v-model="addSite.site" />
                            <span class="text-danger">{{ errors.first('Site') }}</span>
                        </div>
                        <hr />
                        <h5><b>Add alerts</b></h5>
                        <p>
                            You can add same email to different log level. Use different
                            emails in order to escalate the error logs
                        </p>

                        <div class="container" v-for="(lv, index) in label" :key="index">
                            <div class="row">
                                <div :class="'col-sm-2 text-uppercase font-weight-bold pt-2 text-'+severity[index]">{{lv.name}}</div>
                                <div class="col-sm-8">
                                    <input type="text"  :name="lv.name+'-Email'" class="form-control" v-validate="'required'" v-model="addSite.label[index]" placeholder="Enter Email address">
                                    <span class="text-danger">{{ errors.first(lv.name+'-Email') }}</span>
                                </div>
                                <div class="col-sm-2">
                                    <input type="number"  :name="lv.noOfOccurance+'-noOfOccurance'"  class="form-control" v-validate="'required'" v-model.number="addSite.noOfOccurance[index]" placeholder="No.of occurencess">
                                    <span class="text-danger">{{ errors.first(lv.name+'-noOfOccurance') }}</span>
                                </div>
                            </div> <br>
                        </div>

                        <p id="error"></p>
                    </form>
                    <div v-if="add_site">
                        <CodeModal :details='add_site' />
                    </div>
                </div>
                <div class="modal-footer">
                    <router-link :to="{ name: 'View-Site', params: { site: add_site.data.site_id }}" data-dismiss="modal" v-if="add_site" class="btn btn-warning text-dark text-center">View Site</router-link>

                    <button type="button" class="btn btn-primary" v-show="clicked" @click="Submit">
                        Save
                    </button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapState,
    mapActions
} from "vuex";
import CodeModal from './CodeModal';
export default {
    props: ['loadingNew'],
    data() {
        return {
            clicked: true,
            Localsite: "",

        };
    },

    /* 
        calling the required state from
        dashboard Vuex
    */
    computed: {
        ...mapState("config", ["severity", "label"]),
        ...mapState("dashboard", ["addSite", "add_site", "dashboard", "addSite"]),
    },

    /* 
        dispatching the actions to the dashboard Vuex
    */

    methods: {
        ...mapActions("dashboard", ["addSiteDetails", "fetchDashboard", "removeAddSite"]),
        Submit() {
            this.$validator.validateAll().then(success => {
                if (success) {
                    this.addSiteDetails(this.addSite)
                        .then(res => {
                            this.$swal(
                                'Added!',
                                'Your Site has been added.',
                                'success'
                            )
                            this.$store.commit("dashboard/REMOVE_ADD_SITE");
                            this.fetchDashboard('')
                        })
                    this.clicked = false;

                }
            })

        },
        close() {
            this.$store.commit("dashboard/REMOVE_ADD_SITE_MODAL");
        },

    },

    components: {
        CodeModal
    },

};
</script>

<style>
#square {
    z-index: 1;
    position: absolute;
    left: 50%;

}
</style>
