<template>
<div>

    <div class="modal" tabindex="-1" role="dialog" id="EditModal">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Edit Site</h5> 
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <!-- <div v-if="loadingEdit" id="square">
                        <square></square>
                    </div> -->
                    <!-- <div class="alert alert-success" role="alert" v-if="editSite.alerts == []">
                        {{editSite.message}}
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div> -->
                    <form>
                        <div class="form-group" style="padding-bottom: 8px">
                            <label for="exampleInputEmail1">Site Name</label>
                            <input type="text" class="form-control" name="Site" id="site" v-validate="'required'" placeholder="Enter site" v-model="editSite.site" />
                            <span class="text-danger">{{ errors.first('Site') }}</span>
                        </div>
                        <hr />
                        <h5><b>Add alerts</b></h5>
                        <p>
                            You can add same email to different log level. Use different
                            emails in order to escalate the error logs
                        </p>

                        <div class="container" v-for="(data,index) in editSite.alerts" :key="data.id">
                            <div class="row">
                                <!-- 1 Row -->
                                <div :class="'col-sm-2 text-uppercase font-weight-bold text-'+severity[index]">{{data.alert_label}}</div>
                                <div class="col-sm-8 ">
                                    <input type="text" class="form-control" :name="data.alert_label+'-Email'"  placeholder="Enter email" v-model="data.alert_value" />
                                    <!-- <span class="text-danger">{{ errors.first(data.alert_label+'-Email') }}</span> -->
                                </div>
                                <div class="col-sm-2 ">
                                    <input type="text" class="form-control" :name="data.alert_label+'-Email'"  placeholder="Enter email" v-model="data.noOfOccurance" />
                                    <!-- <span class="text-danger">{{ errors.first(data.alert_label+'-Email') }}</span> -->
                                </div>

                            </div><br />
                        </div>

                        <p id="error"></p>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal"  @click="Update(EditData,editSite)">
                        Update
                    </button>
                    <button type="button" class="btn btn-secondary" @click="close" data-dismiss="modal">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapState,
    mapActions
} from "vuex";
export default {
    props: ['EditData', 'loadingEdit'],
    data() {
        return {
            data: {
                alert_value: ''
            }
        };
    },

    /* 
        Watcher here is used to watch the 
        the id for the respective site 
        and fetch the details.
    */
    watch: {
        EditData: function (val) {  
            this.$store.commit("dashboard/REMOVE_EDIT_SITE_DATA");
            this.EditSite(this.EditData);   
        }
    },

    /* 
        calling the required state from
        dashboard Vuex and config
    */
    computed: {
        ...mapState("config", ["severity", "label"]),
        ...mapState("dashboard", ["addSite", "data", "updateData", "editSite", ]),
    },

    /* 
        dispatching the actions to the dashboard Vuex
    */
    methods: {
        ...mapActions("dashboard", ["updateSiteDetails", "EditSite", "fetchDashboard", "removeUpdate"]),
        Update(id, editSite) {
            this.$validator.validateAll().then(success => {
                if (success) {
                    let params = {
                        id,
                        editSite
                    }
                    this.updateSiteDetails(params)
                        .then(response => {
                            console.log(this.updateData.message,"update");
                            if(response){
                                this.$swal(
                                'Updated!',
                                this.updateData.message,
                                'success'
                                )
                            }
                            // this.$store.commit("dashboard/REMOVE_EDIT_SITE"); 
                            this.fetchDashboard('')
                        }).catch((e)=>{
                             this.$swal(
                                'Updated!',
                                e.this.updateData.message,
                                'error'
                            )
                        })
                }
            })
        },
        close() {
            this.$store.commit("dashboard/REMOVE_EDIT_SITE");
        }

    },

    beforeCreate(){
        this.EditSite(this.EditData); 
    }
};
</script>

<style>
#square {
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 30%;
}
</style>
