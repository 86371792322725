export default {
    state: {
        severity: [
            'default',
            'default',
            'info',
            'warning',
            'warning',
            'warning',
            'warning',
            'danger',
        ],
        label: [
            {
                name: 'LOG INFO',
            },
            {
                name: 'LOG DEBUG',
            },
            {
                name: 'LOG NOTICE',
            },
            {
                name: 'LOG WARNING',
            },
            {
                name: 'LOG ERROR',
            },
            {
                name: 'LOG CRITICAL',
            },
            {
                name: 'LOG ALERT',
            },
            {
                name: 'LOG EMERGENCY',
            }
        ],
        site: ''
    }
}