<template>
<div>

    <!-- Modal -->
    <div class="modal fade bd-example-modal-lg" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Stack Trace</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12" v-for="stack in stackTrace" :key="stack.id">
                                <p class="text-center text-justify">{{ stack.logs }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="close" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <!-- <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            
            <div class="modal-content" v-for="stack in stackTrace" :key="stack.id">
                <div class="container">
                    
                </div>
            </div>
        </div>
    </div> -->
</div>
</template>

<script>
import {
    mapState,
    mapActions
} from "vuex";
export default {
    props: ['loadingStack'],
    data() {
        return {

        }
    },

    /* 
        calling the required state from
        dashboard Vuex
    */
    computed: {
        ...mapState("dashboard", ["stackTrace", "loading"])
    },

    /* 
        dispatching the actions to the dashboard Vuex
    */
    methods: {
        ...mapActions("dashboard", ["removeStack"]),
        close() {
            this.removeStack()
        }
    }
};
</script>

<style>
#square {
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 30%;
}
</style>
