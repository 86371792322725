<template>
<div>
    <div v-if="loading" id="square">
        <square></square>
    </div>
    <!-- <div class="alert alert-success" role="alert" v-if="resolve">
        {{ resolve }}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div> -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-header text-uppercase font-weight-bold">
                <div class="row">
                    <div class="col-sm-3">{{key}}</div>
                    <div class="col-sm-7">
                        <div class="input-group">
                            <input type="text" placeholder="Source, Message" class="form-control" v-model="searching.skey">
                            <input type="date" placeholder="From Date" class="form-control" v-model="searching.startFrom">
                            <input type="date" placeholder="To Date" class="form-control" v-model="searching.endFrom">
                            <button class="btn btn-outline-secondary ml-2" @click="search"><i><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" class="icon"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="10" cy="10" r="7"></circle><line x1="21" y1="21" x2="15" y2="15"></line></svg></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead class="table-dark">
                            <tr>
                                <th scope="col">Severity</th>
                                <th scope="col">Message</th>
                                <th scope="col">Source</th>
                                <!-- <th scope="col">File / Line</th> -->
                                <th scope="col">Repeats</th>
                                <th scope="col">Repeated On</th>
                                <th scope="col">Created On</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            <p v-if="errors1" class=" font-weight-bold text-center pt-5">{{ errors1 }}</p>
                            <tr v-for="st in sites.data" :key="st.id">
                                <td :class="textColor(st.severity) + ' text-uppercase font-weight-bold'">{{getSeverityData(st.severity)}}</td>
                                <td>{{ st.message }}</td>
                                <td>{{ st.source }}</td>
                                <!-- <td>{{ st.logs }}</td> -->
                                <td>{{ st.total_occurance }}</td>
                                <td>{{ dateFormat(st.repeated_at ? st.repeated_at :  st.created_at )}} </td>
                                <td>{{ dateFormat(st.created_at)}} </td>

                                <td>
                                    <a href="#" class=" nav-link font-weight-bold text-primary" data-toggle="modal" data-target="#exampleModal" @click.prevent="stack(st.id)">
                                        Stack Track
                                    </a>
                                    <a href="#" class="nav-link font-weight-bold text-danger" @click.prevent="resolveStack(st.id)">
                                        Resolve
                                    </a>

                                    <StackTrack :loadingStack="loading" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="container">
        <div class="row">
            <div class="col-sm-4"></div>
            <div class="col-sm-4 text-center">
                <pagination :data="sites" @pagination-change-page="getResults">
                    <span slot="prev-nav"> Previous</span>
                    <span slot="next-nav">Next </span>
                </pagination>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapState,
    mapActions
} from "vuex";
import StackTrack from "../Modals/StackTrack";
import moment from "moment";
export default {
    props: ["id", "key"],
    data() {
        return {
            message: "",
            searching:{
                skey:'',
                startFrom:'',
                endFrom:''
            },
            /*
                returning created_at Date in the
                human readable date format
            */

            dateFormat(date) {
                if (date) {
                    return moment.utc(date).format("dddd, MMMM Do YYYY, h:mm:ss a");
                } else {
                    return "--";
                }
            },
        };
    },
    components: {
        StackTrack,
    },

    /*
        calling all the state from dashboard Vuex
    */
    computed: {
        ...mapState("dashboard", [
            "sites",
            "loading",
            "stackTrace",
            "resolve",
            "errors1",
        ]),
    },

    /*
        dispatching the actions to the dashboard Vuex
    */
    methods: {
        ...mapActions("dashboard", [
            "fetchSite",
            "fetchstackTrace",
            "fetchResolve",
            "searchSite"
        ]),
        stack(id) {
            this.fetchstackTrace(id);
        },
        resolveStack(id) {
            this.fetchResolve(id)
                .then(res => {
                    this.$swal(
                        'Resolved!',
                        'Your file has been resolved.',
                        'success'
                    )
                    this.fetchSite([this.id, '']);
                })

        },

        // pagination method
        getResults(page = 1) {
            let query = '?page=' + page
            this.fetchSite([this.id, query]);
        },

        getSeverityData(id) {
            if (id == 0) {
                return 'LOG_INFO';
            }
            if (id == 1) {
                return 'LOG_DEBUG';
            }
            if (id == 2) {
                return 'LOG_NOTICE';
            }
            if (id == 3) {
                return 'LOG_WARNING';
            }
            if (id == 4) {
                return 'LOG_ERROR';
            }
            if (id == 5) {
                return 'LOG_CRITICAL';
            }
            if (id == 6) {
                return 'LOG_ALERT';
            }
            if (id == 7) {
                return 'LOG_EMERGENCY';
            }
        },

        textColor(id) {
            if (id == 0) {
                return 'text-dark';
            }
            if (id == 1) {
                return 'text-dark';
            }
            if (id == 2) {
                return 'text-info';
            }
            if (id == 3) {
                return 'text-warning';
            }
            if (id == 4) {
                return 'text-warning';
            }
            if (id == 5) {
                return 'text-warning';
            }
            if (id == 6) {
                return 'text-warning';
            }
            if (id == 7) {
                return 'text-danger';
            }
        },

        search(){
            this.searchSite({
                searchquery:this.searching,
                id:this.id
            })
        }
    },

    /*
        fetching the respective site details
    */
    created() {
        this.fetchSite([this.id, '']);
    },
};
</script>

<style>
.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: #f4f4f4;
    border-color: #DDDDDD;
    color: inherit;
    cursor: default;
    z-index: 2;
}

.pagination>li>a,
.pagination>li>span {
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    color: inherit;
    float: left;
    line-height: 1.42857;
    margin-left: -1px;
    padding: 4px 10px;
    position: relative;
    text-decoration: none;
}

#square {
    z-index: 1;
    position: absolute;
    left: 50%;
}
</style>
