<template>
<div>
    <!-- <div v-if="loading" id="square">
        <square></square>
    </div> -->
    <!-- <div class="alert alert-danger" role="alert" v-if="deleteMessage">

        {{ deleteMessage }}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div> -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-2 text-dark font-weight-bold" style="padding-top: 8px;">
                                Dashboard
                            </div>
                            <div class="col-md-8">
                                <input class="form-control mr-sm-2" type="text" v-model="textSearch" v-debounce:1s="myFunc" placeholder="Search by name" aria-label="Search">
                            </div>
                            <div class="col-md-2" style="text-align: end">
                                <button data-toggle="modal" data-target='#exampleModal' class="btn btn-danger font-weight-bold">
                                    New
                                </button>
                                <!-- Modal -->
                                <Modal :loadingNew="loading" />
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">

                            <div class="col-sm-4" style="margin-bottom: 20px" v-show="hideData != dash.id " v-for="(dash,index) in dashboard.data" :key="dash.id">
                                <div class="card text-center rounded">

                                    <a href="#"> <i class="far fa-times-circle" style="padding: 5px; padding-left: 350px; color: red" @click.prevent="deletes(dash.id,index)"></i></a>

                                    <div class="card-body">
                                        <h5 class="card-title text-center">{{ dash.name }}</h5>
                                        <br />
                                        <router-link :to="{ name: 'View-Site', params: { site: dash.id }}" class="btn btn-sm btn-info font-weight-bold text-white text-center">View Site</router-link>
                                        &nbsp;
                                        <span>&nbsp;&nbsp;
                                            <a href="#" data-toggle="modal" :data-target="'#keyModal'" class="text-primary" @click="modelId = dash.id">
                                                <i class="fas fa-key"></i>
                                            </a>
                                        </span>
                                        <span>&nbsp;&nbsp;
                                            <a href="#" data-toggle="modal" :data-target="'#EditModal'" class="text-dark" @click.prevent="editModel = dash.id">
                                                <i class="fas fa-edit"></i>
                                            </a>
                                        </span>
                                        <!-- <span>&nbsp;&nbsp;
                                            <toggle-button @change="onChangeEventHandler(dash.id)"
                                                            v-model="dash.status"
                                                            :labels="{checked: 'On', unchecked: 'Off'}"
                                                            style="margin-top: 8px;"
                                                            />
                                        </span> -->
                                    </div>
                                </div>
                            </div>
                            <KeyModal :keyFetch="modelId" :loadingKey="loading" />
                            <EditModal :EditData="editModel" :loadingEdit="loading" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div><br>
    <div class="container">
        <div class="row">
            <div class="col-sm-4"></div>
            <div class="col-sm-4 text-center">
                <pagination :data="dashboard" @pagination-change-page="getResults">
                    <span slot="prev-nav"> Previous</span>
                    <span slot="next-nav">Next </span>
                </pagination>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Modal from "./Modals/AddSite";
import KeyModal from "./Modals/KeyModal";
import EditModal from "./Modals/EditModal";
import {
    mapState,
    mapActions
} from "vuex";
export default {
    data() {
        return {
            modelId: '',
            editModel: '',
            hideData: '',
            message:'',
            textSearch:''

        };
    },

    components: {
        Modal,
        KeyModal,
        EditModal
    },

    // calling the state from dashboard Vuex
    computed: {
        ...mapState("dashboard", ["dashboard", "loading", "deleteMessage"]),
    },

    // dispatching the actions to the dashboard
    methods: {
        ...mapActions("dashboard", ["fetchDashboard", "deleteDashboard"]),

        deletes(id, indexID) {

            this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.hideData = id
                    this.deleteDashboard({
                            id,
                            indexID
                        })
                        .then(res => {
                            if (this.deleteMessage) {
                                this.hideData = '',
                                    this.$swal({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: this.deleteMessage,
                                    })

                            }

                        })
                }
            }).catch(error => {

            })

        },
        // pagination method
        getResults(page = 1) {
            let query = '?page=' + page +'&search='+ this.textSearch
            this.fetchDashboard(query);
        },

        onChangeEventHandler(dash){
            axios.get('/api/enable-disable-complete-site/'+dash)
            .then((response)=>{
                this.message = response.data.message
                this.$swal(
                                'Updated!',
                                this.message,
                                'success'
                            )
            })
        },

        async myFunc(){
           await this.getResults()
        }
    },

    /*calling the lifecycle everytime when
    component load
    and listing the created Sites  */

    created() {
       this.fetchDashboard('');
    },
};
</script>

<style scoped>
.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: #f4f4f4;
    border-color: #DDDDDD;
    color: inherit;
    cursor: default;
    z-index: 2;
}

.pagination>li>a,
.pagination>li>span {
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    color: inherit;
    float: left;
    line-height: 1.42857;
    margin-left: -1px;
    padding: 4px 10px;
    position: relative;
    text-decoration: none;
}

#square {
    z-index: 1;
    position: absolute;
    left: 50%;

}
.pl-5, .px-5 {
    padding-left: 10rem!important;
}
</style>
