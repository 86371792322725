<template>
<div>
    <div v-if="loading" id="square">
        <square></square>
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">Login</div>

                    <div class="card-body">
                        <form>
                            <div class="form-group row">
                                <label for="email" class="col-md-4 col-form-label text-md-right">Email Address</label>

                                <div class="col-md-6">
                                    <input id="email" type="email" class="form-control" name="email" value="" required autocomplete="off" autofocus v-model="login.email" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>

                                <div class="col-md-6">
                                    <input id="password" type="password" class="form-control" name="password" required autocomplete="off" v-model="login.password" />
                                </div>
                            </div>

                            <div class="form-group row mb-0">
                                <div class="col-md-8 offset-md-4">
                                    <button type="button" @click="submit" class="btn btn-primary">
                                        Login
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios"
export default {
    data() {
        return {
            login: {
                email: '',
                password: ''
            },
            loading: false
        }
    },

    /* 
        dispatching the actions to the dashboard Vuex
    */
    methods: {
        async submit() {
            try {
                this.loading = true;
                let response = await axios.post('/api/login', this.login)
                    .then(res => {
                        /* 
                            getting the token from backend and storing 
                            it in the localStorage.
                        */
                        localStorage.setItem('token', res.data.data.token)
                        this.login = {
                            email: '',
                            password: ''
                        }
                        this.$router.push('/dashboard').then(res => {
                            /* this.$swal({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                icon: 'success',
                                title: 'Signed in successfully'
                            }) */
                        }).catch(e => {
                            console.log(e)
                        })
                    })

            } catch (error) {

            } finally {
                this.loading = false;
            }
        }
    },

    /* 
        checking that if the user is logged in
        then user cannot redirect to the login page
        again. User should remain on dashboard.
    */
    created() {
        let response = localStorage.getItem('token');
        if (response) {
            this.$router.push('/dashboard')
                .then(res => {})
        } else {
            this.$router.push('/');
        }
    }

};
</script>

<style>
#square {
    z-index: 1;
    position: absolute;
    left: 50%;

}
</style>
