export default {
    state:{
        login:{
            email:'',
            password:''
        }
    },

    mutations:{

    },

    actions:{

    }
}